import { ReactSVG } from "react-svg";
import { motion, AnimatePresence } from "framer-motion";

import {
  Description,
  SubeixoContent,
  SubeixoHeader,
  IconContainer,
  ProductName,
  BrandContainer,
  SvgBrandContainer,
  SubeixoHeaderImageContainer,
  SubeixoContentImageBackground,
  ButtonContainer,
} from "./styles";

import { CarouselComponent } from "../../components/carousel";
import MediaMatch from "../../components/mediaMatch";
import React, { useEffect, useState } from "react";
import { getAxisByIdService, homeListSortedService } from "../../services/home";
import { safelyParseJSON } from "../../utils/parse";
import { getThemeService } from "../../services/theme";
import "external-svg-loader";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { getProductByIdService } from "../../services/product";
import { Breadcrumb } from "../../components/breadcrumb";
import { buildBreadcrumb, removeFromBreadcrumb } from "../../utils/createBreadcrumb";
import { Button } from "../../components/button";
import Loader from "../../components/loader";

function Subeixo() {
  const [theme, setTheme] = useState<any>();
  const [axis, setAxis] = useState<any>();
  const [children, setChildren] = useState<any>([]);
  const [breadcrumb, setBreadcrumb] = useState<any[]>([]);
  const [hasBackToHome, setHasBackToHome] = useState(false);
  const [backButtonRoute, setBackButtonRoute] = useState("/home");
  const [isLoading, setIsLoading] = useState(false);
  const [referenceColor, setReferenceColor] = useState<string>('#000000');
  const [referenceSubtitle, setReferenceSubtitle] = useState<string>('');
  const params = useParams();
  const navigate = useNavigate();

  async function fillFooter(footer: any[]) {
    const tempAxis = [];
    for await (const f of footer) {
      const responseAxis = await getAxisByIdService(f.id);
      tempAxis.push(responseAxis.data.axis);
    }
  }

  async function fillHome(home: any[]) {
    const tempAxis = [];
    for await (const h of home) {
      const responseAxis = await getAxisByIdService(h.id);
      tempAxis.push(responseAxis.data.axis);
    }
  }

  async function axisChildrenMapper(axisList: any) {
    const tempAxisChildren = [];
    for await (const child of axisList) {
      if (child.type === "axis") {
        const responseAxis = await getAxisByIdService(child.id);
        tempAxisChildren.push({ ...responseAxis.data.axis, type: "axis" });
      }
      if (child.type === "product") {
        const responseAxis = await getProductByIdService(child.id);
        tempAxisChildren.push({
          ...responseAxis.data.product,
          type: "product",
        });
      }
    }
    setChildren(tempAxisChildren);
  }

  async function load() {
    setIsLoading(true)
    const axisResponse = await getAxisByIdService(params.id);
    const homeAxisSortedResponse = await homeListSortedService();
    const themeResponse = await getThemeService();

    setAxis(safelyParseJSON(axisResponse.data.axis));
    await fillFooter(safelyParseJSON(homeAxisSortedResponse.data.order.footer));
    await fillHome(safelyParseJSON(homeAxisSortedResponse.data.order.order));
    setTheme(safelyParseJSON(themeResponse.data.theme));
    await axisChildrenMapper(safelyParseJSON(axisResponse.data.axis.children));
    setIsLoading(false);
  }

  const location = useLocation();

  useEffect(() => {
    load();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.id]);

  useEffect(() => {
    async function loadBreadcrumb() {
      const breadcrumbs = buildBreadcrumb(true);
      const axisResponse = await getAxisByIdService(params.id);
      setBreadcrumb(breadcrumbs);

      await axisChildrenMapper(
        safelyParseJSON(axisResponse.data.axis.children)
      );
    }
    loadBreadcrumb();
  }, [location]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    async function loadReferenceColor() {
      setReferenceSubtitle('')
      if (breadcrumb?.length) {
        const responseAxis = await getAxisByIdService(breadcrumb[0]?.id);
        setReferenceColor(responseAxis.data.axis.color)
        setReferenceSubtitle(breadcrumb[0]?.title)
      } else {
        setReferenceColor(axis?.color)
      }
    }

    loadReferenceColor();

  }, [breadcrumb, axis, location])

  useEffect(() => {
    if (breadcrumb?.length == 0) {
      setHasBackToHome(false);
      setBackButtonRoute(`/home`);
    }
    if (breadcrumb?.length > 0) {
      setHasBackToHome(true);
      setBackButtonRoute(
        `/subeixo/${breadcrumb[breadcrumb.length - 1]?.id}`
      );
    }

  }, [breadcrumb]);
  useEffect(() => {
    return () => {}
  }, []);
  return (
    <>
      <>
      {isLoading && <Loader />}
        <SubeixoHeader>
          <MediaMatch greaterThan="situacao">
            <SubeixoHeaderImageContainer>
              {theme?.header_collaborative && <ReactSVG src={theme?.header_collaborative} />}
            </SubeixoHeaderImageContainer>
          </MediaMatch>
          <MediaMatch lessThan="situacao">
            <SubeixoHeaderImageContainer>
              {theme?.header_situation && <ReactSVG src={theme?.header_situation} />}
            </SubeixoHeaderImageContainer>
          </MediaMatch>

          <AnimatePresence mode="wait">
            <motion.div
              key={1}
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 1, delay: 1 }}
              exit={{ opacity: 0, x: 2000, animationDuration: "1s" }}
            >
              <BrandContainer>
                <MediaMatch greaterThan="situacao">
                  {/.svg$/.exec(axis?.icon) ? (
                    <SvgBrandContainer>
                      <ReactSVG src={axis?.icon} />
                    </SvgBrandContainer>
                  ) : (
                    <>
                      <IconContainer src={axis?.icon} />
                    </>
                  )}
                </MediaMatch>
                <MediaMatch lessThan="situacao">
                  {/.svg$/.exec(axis?.icon) ? (
                    <SvgBrandContainer>
                      <ReactSVG src={axis?.icon} />
                    </SvgBrandContainer>
                  ) : (
                    <>
                      <IconContainer src={axis?.icon} />
                    </>
                  )}
                </MediaMatch>

                <div style={{zIndex:'1'}}>
                  <ProductName highlight>{referenceSubtitle ? axis?.title : 'Produtos'}</ProductName>
                  <ProductName>{referenceSubtitle || axis?.title}</ProductName>
                </div>
              </BrandContainer>

              <Description>{axis?.description}</Description>
            </motion.div>
          </AnimatePresence>
        </SubeixoHeader>

        <AnimatePresence mode="wait">
          <motion.div
            key={1}
            initial={{ opacity: 0, scale: 0 }}
            animate={{ opacity: 1, x: 0, scale: 1 }}
            transition={{ duration: 1, delay: 0.5 }}
            exit={{ opacity: 0, x: 2000, animationDuration: "1s" }}
          >
            <SubeixoContent>
              <Breadcrumb
                isSingle
                path={breadcrumb?.map((item: any) => item.title)}
                active={axis?.title}
                color={referenceColor}
              />
              <SubeixoContentImageBackground color={referenceColor}>
                <MediaMatch greaterThan="situacao">
                  {theme?.container2_collaborative && <ReactSVG src={theme?.container2_collaborative} />}
                </MediaMatch>
                <MediaMatch lessThan="situacao">
                  {theme?.container2_collaborative && <ReactSVG src={theme?.container1_situation} />}
                </MediaMatch>
              </SubeixoContentImageBackground>

              <CarouselComponent
                items={children}
                theme={theme}
                color={referenceColor}
              ></CarouselComponent>
            </SubeixoContent>
          </motion.div>
        </AnimatePresence>

        <ButtonContainer>
          <Button
            variant="normal"
            onClick={() => {
              removeFromBreadcrumb(1);
              navigate(backButtonRoute);
            }}
            theme={theme}
            borderColor={referenceColor}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="w-6 h-6"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M15.75 19.5L8.25 12l7.5-7.5"
              />
            </svg>
            Voltar
          </Button>

          {hasBackToHome && (
            <Button
              variant="large"
              onClick={() => navigate("/home")}
              theme={theme}
              borderColor={theme?.main_color}
            >
              {theme?.icon_situation && <ReactSVG id="home-button" src={theme?.icon_situation} />}
              Home
            </Button>
          )}
        </ButtonContainer>
      </>
    </>
  );
}

export default (Subeixo)