import Lottie from "lottie-react";
import loader from "../../assets/images/lottie/loader.json";
import { LoaderOverlay, LoaderWrapper } from "./styles";
import { AnimatePresence, motion } from "framer-motion";

export default function Loader() {
    return (
        <AnimatePresence mode="wait">
          <motion.div
            key={1}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 1, delay: 2 }}
            exit={{ opacity: 0 }}
          >
            <LoaderOverlay>
                <LoaderWrapper>
                    <Lottie animationData={loader} loop={true} />
                </LoaderWrapper>
            </LoaderOverlay>
            </motion.div>
        </AnimatePresence>
    )
}


