import { styled } from "styled-components";
import { customMedia } from "../mediaMatch";

interface PropsBreadcrumbWrapper {
    isGrid?: boolean
    isSingle?: boolean
}
export const BreadcrumbWrapper = styled.div<PropsBreadcrumbWrapper>`
    position: absolute;
    left: 200px;
    top: ${({isGrid}) => isGrid ? '0': '60px'};
    top:${({isSingle}) => isSingle ? '60px': '0'};
    z-index: 1;
    ${customMedia.greaterThan('situacao')`
        left: 60px;
        top: -60px;
        
    `}
`

export const BreadcrumbPath = styled.span`
    font-size: 60px;
    font-weight: 300;
    ${customMedia.greaterThan('situacao')`
        font-size: 24px;
    `}
`

interface PropsActiveItem {
    color?: string
}
export const ActiveItem = styled.span<PropsActiveItem>`
    font-size: 60px;
    font-weight: 700;
    color: ${(props) => props.color ?? 'white'};

    ${customMedia.greaterThan('situacao')`
        font-size: 24px;
    `}
`