import { styled } from "styled-components";
import { customMedia } from "../mediaMatch";
import { adjustHue, darken, desaturate, lighten, saturate } from "polished";

export const GradientBox = styled.div<PropsButtonWrapper>`
  
  display: flex;
  align-items: center;
  margin: auto;

  width: 180px;
  height: 180px;

  position: absolute;
  bottom: 48px;
  right: 90px;
  box-sizing: border-box;

  border: 5px;
  color: #FFF;
  background: #000;
  background-clip: padding-box; 
  border: solid 5px transparent; 
  border-radius: 50%;

  &:before {
    content: '';
    position: absolute;
    top: 0; right: 0; bottom: 0; left: 0;
    z-index: 3;
    margin: -5px; 
    border-radius: inherit; 
    background: ${({theme}) => `linear-gradient(to right, ${lighten('0.2', theme?.main_color ?? '#000')}, ${lighten('0.463',adjustHue('280',  theme?.main_color ?? '#000'))})`}; 
    /* #2C86C6 */
  }

    ${customMedia.greaterThan('situacao')`
        width: 100px;
        height: 100px;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        gap: 12px;
        font-size: 24px;
        bottom: -60px;
        left: 30px;
        right: unset;
    `}
  `

interface PropsButtonWrapper {
    theme?:any;
}
export const ButtonWrapper = styled.button<PropsButtonWrapper>`
    width: 170px;
    height: 170px;
    z-index: 3;
    border-radius: 25%;
    border-color: transparent;
    display: inline-flex;
    justify-content: center;
    align-items: center;


    border-radius: 50%;

    background-color: ${({theme}) => theme.main_color };

    font-size: 80px;
    font-weight: 500;
    color: white;
    svg {
        width: 90%
    }

    ${customMedia.greaterThan('situacao')`
        width: 90px;
        height: 90px;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        gap: 12px;
        font-size: 24px;
        svg {
            width: 100%
    `}
`