import React from "react";
import { ButtonWrapper, GradientBox } from "./styles";

const ButtonExpand = ({
  theme,
  children,
  ...props
}: {
  theme: any;
  children: React.ReactNode;
  borderColor: any;
  variant: "normal" | "large";
} & React.HTMLProps<HTMLButtonElement>) => {
  return (
    <>
      <GradientBox id="gradient" theme={theme}>
        <ButtonWrapper onClick={props.onClick} theme={theme}>
          {children}
        </ButtonWrapper>
      </GradientBox>
    </>
  );
};

export { ButtonExpand };
