import styled, { css } from "styled-components";
import { generateMedia } from "styled-media-query";

type customBreakPoint = "situacao" | "colaboradores";

export type MediaMatchProps = {
  lessThan?: customBreakPoint;
  greaterThan?: customBreakPoint;
};

export const customMedia = generateMedia({
  colaboradores: "4992px",
  situacao: "4321px",
});

const mediaMatchModifiers = {
  lessThan: (size: customBreakPoint) => css`
    ${customMedia.lessThan(size)` display: block `}
  `,

  greaterThan: (size: customBreakPoint) => css`
    ${customMedia.greaterThan(size)` display: block `}
  `,
};

export default styled.div<MediaMatchProps>`
  ${({ lessThan, greaterThan }) => css`
    display: none;
    ${!!lessThan && mediaMatchModifiers.lessThan(lessThan)}
    ${!!greaterThan && mediaMatchModifiers.greaterThan(greaterThan)}
  `}
`;
