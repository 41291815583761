import { styled } from "styled-components";
import { customMedia } from "../mediaMatch";

interface PropsCarousel {
    color: string;
    isGrid: boolean;
    isSingle?: boolean;
    isProductPage?: boolean;
}

export const CarouselWrapper = styled.div<PropsCarousel>`
    width: 3186px;
    
    .carousel {
        height: 100%;
    }
    .carousel-inner {
        overflow: ${({isProductPage}) => isProductPage ? 'hidden' : 'hidden'};
        ${customMedia.greaterThan('situacao')`
           right: ${({isSingle}: {isSingle: any}) => isSingle ? '150px' : '0'};
        `}
    }
    .carousel-indicators {
        display: ${({isSingle}) => isSingle ? 'none' : ''};
        /* position: fixed;
        bottom: ${({isGrid}) => isGrid ? '640px' : '1033px'};  */
        bottom: -200px;
        button {
            border: 0;
            width: 200px;
            height: 30px;
            border-radius: 100px;
            background-color: ${(props) => props.color};
            border-color: ${(props) => props.color};
            margin: 0 40px;
            ${customMedia.greaterThan('situacao')`
                width: 76px;
                height: 12px;
                margin: 0 16px;
            `}
        }
        ${customMedia.greaterThan('situacao')`
            position: absolute;
            bottom: -36px;
        `}

    }
    .carousel-control-prev {
        opacity: 1;
        left: -380px;
        ${customMedia.greaterThan('situacao')`
            display: ${({isSingle}: {isSingle: any}) => isSingle ? 'none' : ''};
        `}
    }
    .carousel-control-next {
        opacity: 1;
        right: -380px;
        ${customMedia.greaterThan('situacao')`
            display: ${({isSingle}: {isSingle: any}) => isSingle ? 'none' : ''};
        `}
    }

    ${customMedia.greaterThan('situacao')`
        width: ${({isSingle}: {isSingle: any}) => isSingle ? '4500px' : '3343px'};
        position: ${({isSingle}: {isSingle: any}) => isSingle ? 'absolute' : ''};
        left: ${({isSingle}: {isSingle: any}) => isSingle ? '150px' : ''};
        top: ${({isSingle}: {isSingle: any}) => isSingle ? '40px' : ''};
        position: absolute;


    `}


`

export const CarouselPageWrapper = styled.div`
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    align-items: center;
    gap: 80px;
    height: 1324px;
    ${customMedia.greaterThan('situacao')`
        height: 200px;
        display: flex;
        gap: 120px;
        justify-content: center;
    `}
`

interface PropsCarouselGridWrapper {
    isSingle?: boolean;
}
export const CarouselGridWrapper = styled.div<PropsCarouselGridWrapper>`
    z-index: 2;
    display: flex;
    /* grid-template-columns: ${({isSingle}) => isSingle ? '1fr': '1fr 1fr '} ;
    grid-template-rows: ${({isSingle}) => isSingle ? '1fr': '1fr 1fr'} ;
    row-gap: 140px;
    column-gap: 80px; */
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    gap: 80px;
    row-gap: 80px;
    height: 1874px;
    ${customMedia.greaterThan('situacao')`
        height: ${({isSingle}: {isSingle: any}) => isSingle ? '600px': '449px'} ; ;
        grid-template-columns: 1fr 1fr 1fr 1fr;
        grid-template-rows: 1fr;
        row-gap: 140px;
        column-gap: 80px;
    `}
    
`

interface PropsContentWrapper {
    isSingle?: boolean 
}

export const ContentWrapper = styled.div<PropsContentWrapper>`
     position: relative;
    width: ${({isSingle}) => isSingle ? '100%':'1540px'};
    height: ${({isSingle}) => isSingle ? '100%':' 866px'};
    overflow: hidden;
    iframe {
        z-index: 3;
        position: absolute;
        /* top: ${({isSingle}) => isSingle ? '960px':'0'};
        left: ${({isSingle}) => isSingle ? '1648px':'0'}; */
        width: 100%;
        height: 100%;
        scale: ${({isSingle}) => isSingle ? '1':'1'};
       
    }
    ${customMedia.greaterThan('situacao')`
        height: 100%;
        width: ${({isSingle}: any) => isSingle ? '1071px':'798px'};
        iframe {
            top: ${({isSingle}: any) => isSingle ? '-120px':'-210px'};
            left: ${({isSingle}: any) => isSingle ? '-230px':'-390px'};
            scale: ${({isSingle}: any) => isSingle ? '0.7':'0.5'};
            width: 1540px;
            height: 865px;
        }
    `}

`

interface PropsPrevIcon {
    color: string
}
export const PrevArrowIcon = styled.div<PropsPrevIcon>`
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);
    left: -380px;
    svg {
        defs {
            linearGradient:last-child {
                stop {
                    stop-color: ${(props) => props.color};
                }
                stop[offset='1'] {
                    stop-color: ${(props) => props.color}bb;
                }
            }
        }
    }
    
    
    ${customMedia.greaterThan('situacao')`
        svg {
            width: 66px;
        }
    `}

`

interface PropsNextIcon {
    color: string
}
export const NextArrowIcon = styled.div<PropsNextIcon>`
    right: -380px;
    svg {
        defs {
            linearGradient:last-child {
                stop {
                    stop-color: ${(props) => props.color};
                }
                stop[offset='1'] {
                    stop-color: ${(props) => props.color}bb;
                }
            }
        }
    }
    ${customMedia.greaterThan('situacao')`
        svg {
        width: 66px;
    }
    `}
`
