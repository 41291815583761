import { ReactSVG } from "react-svg";
import { motion, AnimatePresence } from "framer-motion";

import {
  Description,
  ConteudoContent,
  ConteudoHeader,
  IconContainer,
  ProductName,
  BrandContainer,
  SvgBrandContainer,
  ConteudoHeaderImageContainer,
  ConteudoContentImageBackground,
  ButtonContainer,
  ButtonExpandContainer,
} from "./styles";

import { CarouselComponent } from "../../components/carousel";
import MediaMatch from "../../components/mediaMatch";
import React, { useEffect, useState } from "react";
import { getAxisByIdService } from "../../services/home";
import { safelyParseJSON } from "../../utils/parse";
import { getThemeService } from "../../services/theme";
import "external-svg-loader";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { getProductByIdService } from "../../services/product";
import { Breadcrumb } from "../../components/breadcrumb";
import { buildBreadcrumb, removeFromBreadcrumb } from "../../utils/createBreadcrumb";
import { Button } from "../../components/button";
import { ButtonExpand } from "../../components/button-expand";
import Loader from "../../components/loader";

function Conteudo() {
  const [theme, setTheme] = useState<any>();
  const [product, setProduct] = useState<any>();
  const [children, setChildren] = useState<any>([]);
  const [rootParent, setRootParent] = useState<any>();
  const [breadcrumb, setBreadcrumb] = useState<any[]>([]);
  const [currentIframe, setCurrentIframe] = useState<any>();
  const [iframeIndex, setIframeIndex] = useState<any>();
  const [currentPage, setCurrentPage] = useState<number>();
  const [isLoading, setIsLoading] = useState(false);
  const [referenceSubtitle, setReferenceSubtitle] = useState<string>('');
  const params = useParams();
  const navigate = useNavigate();

  async function load() {
    setIsLoading(true);
    const productResponse = await getProductByIdService(params.idProduto);

    const themeResponse = await getThemeService();

    setProduct(safelyParseJSON(productResponse.data.product));
    setChildren(safelyParseJSON(productResponse.data.product.children));
    setTheme(safelyParseJSON(themeResponse.data.theme));
    setIsLoading(false);
  }

  const location = useLocation();

  const getContent = (id: any) => {
    const iframe = children.filter((content: any, index: any) => {
      if (content.id == id) {
        setIframeIndex(index);
      }

      return content.id == id;
    });
    const index = children.indexOf(id);
    setCurrentIframe(iframe[0]);
  };

  const nextIframe = (currentIframeCB?: any) => {
    setCurrentIframe(currentIframeCB);
  };

  const prevIframe = (currentIframeCB:any) => {
    setCurrentIframe(currentIframeCB);
  };
  useEffect(() => {
    load();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params]);

  useEffect(() => {
    async function loadBreadcrumb() {
      const breadcrumbs = buildBreadcrumb(true);
      const parentResponse = await getAxisByIdService(
        breadcrumbs[0]?.id
      );
      setReferenceSubtitle(breadcrumbs[0]?.title);
      setRootParent(parentResponse.data.axis);
      setBreadcrumb(breadcrumbs);
    }
    loadBreadcrumb();
  }, [location, params.idProduto, currentIframe]);

  useEffect(() => {
    
  }, [children, getContent, params.idConteudo]);

  useEffect(() => {
    setCurrentPage(Number(params.idConteudo?.replace("single-", "") ?? 0));
  }, [params.idConteudo]);

  useEffect(() => {
    getContent(params.idConteudo);
    return () => {}
  }, [params.idConteudo, children]);

  return (
    <>
      <>
        {isLoading && <Loader />}
        <ConteudoHeader>
          <MediaMatch greaterThan="situacao">
            <ConteudoHeaderImageContainer>
              {theme?.header_collaborative &&  <ReactSVG src={theme?.header_collaborative} />}
             
            </ConteudoHeaderImageContainer>
          </MediaMatch>
          <MediaMatch lessThan="situacao">
            <ConteudoHeaderImageContainer>
              {theme?.header_situation &&  <ReactSVG src={theme?.header_situation} />}
             
            </ConteudoHeaderImageContainer>
          </MediaMatch>
          <AnimatePresence mode="wait">
            <motion.div
              key={1}
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 1, delay: 1 }}
              exit={{ opacity: 0, animationDuration: "1s" }}
            >
              <BrandContainer>
                <MediaMatch lessThan="situacao">
                  {/.svg$/.exec(product?.icon) ? (
                    <SvgBrandContainer>
                     {product?.icon && <ReactSVG src={product?.icon} />}
                    </SvgBrandContainer>
                  ) : (
                    <>
                      <IconContainer  src={product?.icon} />
                    </>
                  )}
                </MediaMatch>

                <div style={{zIndex: '1'}}>
                  <MediaMatch lessThan="situacao">
                    <ProductName highlight>{product?.title}</ProductName>
                    <ProductName>{referenceSubtitle}</ProductName>
                  </MediaMatch>
                </div>
              </BrandContainer>

              <MediaMatch lessThan="situacao">
                <Description>{product?.description}</Description>
              </MediaMatch>
            </motion.div>
          </AnimatePresence>
        </ConteudoHeader>

        <AnimatePresence mode="wait">
          <motion.div
            key={1}
            initial={{ opacity: 0, scale: 0 }}
            animate={{ opacity: 1, x: 0, scale: 1 }}
            transition={{ duration: 1, delay: 0.5 }}
            exit={{ opacity: 0 }}
          >
            <ConteudoContent>
              <Breadcrumb
                isGrid
                isSingle
                path={breadcrumb?.map((item: any) => item.title).concat(product?.title)}
                active={currentIframe?.name}
                color={rootParent?.color}
                contentParent={product}
              />
              <ConteudoContentImageBackground color={rootParent?.color}>
                <MediaMatch lessThan="situacao">
                  {theme?.container1_situation && <ReactSVG src={theme?.container1_situation} />}
                </MediaMatch>
              </ConteudoContentImageBackground>

              <MediaMatch greaterThan="situacao">
                <CarouselComponent
                  items={children}
                  theme={theme}
                  color={rootParent?.color}
                  isGrid
                  itemsToShow={4}
                  isSingle
                  currentIndexIframe={currentPage}
                ></CarouselComponent>
              </MediaMatch>

              <MediaMatch lessThan="situacao">
                <CarouselComponent
                  items={children}
                  theme={theme}
                  color={rootParent?.color}
                  isGrid
                  itemsToShow={1}
                  isSingle
                  currentIndexIframe={iframeIndex}
                  nextAction={nextIframe}
                  prevAction={prevIframe}
                ></CarouselComponent>
              </MediaMatch>

              {
                <MediaMatch greaterThan="situacao">
                  <ButtonExpandContainer>
                    <ButtonExpand
                      onClick={() => navigate(`/produto/${product.id}`)}
                      borderColor={"red"}
                      theme={theme}
                      variant="normal"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        className="w-6 h-6"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M9 9V4.5M9 9H4.5M9 9L3.75 3.75M9 15v4.5M9 15H4.5M9 15l-5.25 5.25M15 9h4.5M15 9V4.5M15 9l5.25-5.25M15 15h4.5M15 15v4.5m0-4.5l5.25 5.25"
                        />
                      </svg>
                    </ButtonExpand>
                  </ButtonExpandContainer>
                </MediaMatch>
              }
            </ConteudoContent>
          </motion.div>
        </AnimatePresence>
        <MediaMatch lessThan="situacao">
          <ButtonContainer>
            <Button
              variant="normal"
              onClick={() => {
                navigate(`/produto/${product?.id}`)
              } }
              theme={theme}
              borderColor={rootParent?.color}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="w-6 h-6"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M15.75 19.5L8.25 12l7.5-7.5"
                />
              </svg>
              Voltar
            </Button>

            <Button
              onClick={() => {
                removeFromBreadcrumb(breadcrumb?.length)
                navigate(`/subeixo/${rootParent?.id}`)
              }}
              variant="normal"
              theme={theme}
              borderColor={rootParent?.color}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="w-6 h-6"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M7.217 10.907a2.25 2.25 0 100 2.186m0-2.186c.18.324.283.696.283 1.093s-.103.77-.283 1.093m0-2.186l9.566-5.314m-9.566 7.5l9.566 5.314m0 0a2.25 2.25 0 103.935 2.186 2.25 2.25 0 00-3.935-2.186zm0-12.814a2.25 2.25 0 103.933-2.185 2.25 2.25 0 00-3.933 2.185z"
                />
              </svg>
              Eixo
            </Button>

            { theme?.icon_situation &&
              <Button
                variant="large"
                onClick={() => navigate("/home")}
                theme={theme}
                borderColor={theme?.main_color}
              >
                <ReactSVG id="home-button" src={theme?.icon_situation} />
                Home
              </Button>
            }
          </ButtonContainer>
        </MediaMatch>
      </>
    </>
  );
}

export default (Conteudo)