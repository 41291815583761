import { ReactSVG } from "react-svg";
import { motion, AnimatePresence } from "framer-motion";


import {
  Description,
  HomeContent,
  HomeHeader,
  IconContainer,
  ProductName,
  BrandContainer,
  DataDrivenIcon,
  FooterIcon,
  AxisIconContainer,
  AxisIconLabel,
  SvgContainer,
  SvgBrandContainer,
  HomeHeaderImageContainer,
  HomeContentImageBackground,
} from "./styles";

import { CarouselComponent } from "../../components/carousel";
import MediaMatch from "../../components/mediaMatch";
import React, { useEffect, useState } from "react";
import {
  getAxisByIdService,
  homeListService,
  homeListSortedService,
} from "../../services/home";
import { AxisIcon } from "../../components/icon/styles";
import { safelyParseJSON } from "../../utils/parse";
import { getThemeService } from "../../services/theme";
import "external-svg-loader";
import { Link } from "react-router-dom";
import Loader from "../../components/loader";
import { addToBreadcrumb, removeFromBreadcrumb } from "../../utils/createBreadcrumb";

function Home() {
  const [homeAxis, setHomeAxis] = useState<any[]>([]);
  const [dataDriven, setDataDriven] = useState<any>();
  const [footerAxis, setFooterAxis] = useState<any[]>([]);
  const [theme, setTheme] = useState<any>();
  const [isLoading, setIsLoading] = useState(false);

  async function fillFooter(footer: any[]) {
    const tempAxis = [];
    for await (const f of footer) {
      const responseAxis = await getAxisByIdService(f.id);
      tempAxis.push(responseAxis.data.axis);
    }
    setFooterAxis(tempAxis);
  }

  async function fillHome(home: any[]) {
    const tempAxis = [];
    for await (const h of home) {
      const responseAxis = await getAxisByIdService(h.id);
      tempAxis.push(responseAxis.data.axis);
    }
    setHomeAxis(tempAxis);
  }

  async function load() {
    setIsLoading(true)
    const responseHomeAxis = await homeListService();
    const homeAxisSortedResponse = await homeListSortedService();
    const themeResponse = await getThemeService();
    const dataDrivenResponse = responseHomeAxis.data?.axes?.filter(
      (axis: any) => axis.header
    );
    if (dataDrivenResponse?.length) {
      setDataDriven(dataDrivenResponse[0]);
    }

    await fillFooter(safelyParseJSON(homeAxisSortedResponse.data.order.footer));
    await fillHome(safelyParseJSON(homeAxisSortedResponse.data.order.order));
    setTheme(safelyParseJSON(themeResponse.data.theme));
    setIsLoading(false);
  }

  useEffect(() => {
    removeFromBreadcrumb(10)
    load();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
  }, [homeAxis]);
  useEffect(() => {
    return () => {}
  }, []);

  return (
    <>

      {isLoading && <Loader />}

      <HomeHeader>
        <MediaMatch greaterThan="situacao">
          <HomeHeaderImageContainer>
            {theme?.header_collaborative && <ReactSVG src={theme?.header_collaborative} />}
          </HomeHeaderImageContainer>
        </MediaMatch>
        <MediaMatch lessThan="situacao">
          <HomeHeaderImageContainer>
            {theme?.header_situation && <ReactSVG src={theme?.header_situation} />}
          </HomeHeaderImageContainer>
        </MediaMatch>
        <AnimatePresence mode="wait">
          <motion.div
            key={1}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 1, delay: 1 }}
            exit={{ opacity: 0 }}
          >
            <BrandContainer>
              <MediaMatch greaterThan="situacao">
                {/.svg$/.exec(theme?.icon_situation) ? (
                  <SvgBrandContainer>
                    {theme?.icon_situation && <ReactSVG src={theme?.icon_situation} />}
                  </SvgBrandContainer>
                ) : (
                  <>
                    <IconContainer src={theme?.icon_situation} />
                  </>
                )}
              </MediaMatch>
              <MediaMatch lessThan="situacao">
                {/.svg$/.exec(theme?.icon_collaborative) ? (
                  <SvgBrandContainer>
                    {theme?.icon_collaborative && <ReactSVG src={theme?.icon_collaborative} />}
                  </SvgBrandContainer>
                ) : (
                  <>
                    <IconContainer src={theme?.icon_collaborative} />
                  </>
                )}
              </MediaMatch>

              <div style={{zIndex: '1'}}>
                <ProductName highlight>{theme?.title}</ProductName>
                <ProductName>{theme?.subtitle}</ProductName>
              </div>
            </BrandContainer>

            <Description>
              {theme?.description}
            </Description>
          </motion.div>
        </AnimatePresence>
      </HomeHeader>

      <AnimatePresence mode="wait">
        <motion.div
          key={1}
          initial={{ opacity: 0, scale: 0 }}
          animate={{ opacity: 1, x: 0, scale: 1 }}
          transition={{ duration: 1, delay: 0.5 }}
          exit={{ opacity: 0, x: 2000, animationDuration: "1s" }}
        >
          <HomeContent>
            <HomeContentImageBackground>
              <MediaMatch greaterThan="situacao">
                {theme?.container1_collaborative && <ReactSVG src={theme?.container1_collaborative} />}
              </MediaMatch>
              <MediaMatch lessThan="situacao">
                {theme?.container1_situation && <ReactSVG src={theme?.container1_situation} />}
              </MediaMatch>
            </HomeContentImageBackground>
            {dataDriven ? (
              <DataDrivenIcon>
                <AxisIconContainer>
                  {/.svg$/.exec(dataDriven?.icon) ? (
                    <Link to={`/subeixo/${dataDriven?.id}`} onClick={() => addToBreadcrumb({id: dataDriven.id, type: 'axis', title: dataDriven.title})}>
                      <SvgContainer>
                        {dataDriven?.icon && <ReactSVG src={dataDriven?.icon} />}
                        <AxisIconLabel>{dataDriven?.title}</AxisIconLabel>
                      </SvgContainer>
                    </Link>
                  ) : (
                    <Link style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }} to={`/subeixo/${dataDriven.id}`} onClick={() => addToBreadcrumb({id: dataDriven.id, type: 'axis', title: dataDriven.title})}>
                      <AxisIcon src={dataDriven?.icon} />
                      <AxisIconLabel>{dataDriven?.title}</AxisIconLabel>
                    </Link>
                  )}
                </AxisIconContainer>
              </DataDrivenIcon>
            ) : (
              <></>
            )}

            {
              homeAxis.length > 0 &&
              <CarouselComponent
              items={homeAxis}
              theme={theme}
            />
            }

            <FooterIcon>
              <>
                {footerAxis.map((footer, index) => {
                  return (
                    <AxisIconContainer style={{zIndex: '10'}} key={footer.icon}>
                      {/.svg$/.exec(footer?.icon) ? (
                        <Link to={`/subeixo/${footer?.id}`} onClick={() => addToBreadcrumb({id: footer.id, type: 'axis', title: footer.title})}>
                          <SvgContainer>
                           {footer?.icon && <ReactSVG src={footer?.icon} />}
                            <AxisIconLabel>{footer?.title}</AxisIconLabel>
                          </SvgContainer>
                        </Link>
                      ) : (
                        <Link style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }} to={`/subeixo/${footer?.id}`} onClick={() => addToBreadcrumb({id: footer.id, type: 'axis', title: footer.title})}>
                          <AxisIcon src={footer?.icon} />
                          <AxisIconLabel>{footer?.title}</AxisIconLabel>
                        </Link>
                      )}
                    </AxisIconContainer>
                  );
                })}
              </>
            </FooterIcon>
          </HomeContent>
        </motion.div>
      </AnimatePresence>
    </>
  );
}

export default (Home)