import { styled } from "styled-components";
import { customMedia } from "../mediaMatch";

export const LoaderWrapper = styled.div`
    /* scale: 0.07; */
    position: relative;
    width: 400px;
    margin: 280px;
    ${customMedia.greaterThan('situacao')`
        width: 290px;
    `}
`
export const LoaderOverlay = styled.div`
    background: transparent;
    opacity: 1;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 0;
    display: flex;
    justify-content: flex-end;
    ${customMedia.greaterThan('situacao')`
        font-size: 24px;
        width: 290px;
        -webkit-line-clamp: 2;
    `}
`