import { styled } from "styled-components"
import { customMedia } from "../mediaMatch"

export const AxisIcon = styled.img`
    width: 400px;
    height: 400px;
    ${customMedia.greaterThan('situacao')`
        width: 120px;
        height: 120px;
    `}
`

export const AxisLabel = styled.p`
    font-size: 80px;
    font-weight: 600;
    margin-top: 20px;
    text-align: center;
    ${customMedia.greaterThan('situacao')`
        font-size: 24px;

    `}
`

export const AxisContainer = styled.div`
    width: 730px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    ${customMedia.greaterThan('situacao')`
         width: 290px;
    `}
`

export const SvgContainer = styled.div`
    width: 730px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    
    ${customMedia.greaterThan('situacao')`
       width: 290px;
    `}

    svg {
        width: 400px;
    height: 400px;
    ${customMedia.greaterThan('situacao')`
        width: 120px;
        height: 120px;
    `}
    }

`