import React from "react";
import { ButtonWrapper, GradientBox } from "./styles";

const Button = ({
  theme,
  borderColor,
  children,
  variant = "normal",
  ...props
}: {
  theme: any;
  children: React.ReactNode;
  borderColor: any;
  variant: "normal" | "large";
} & React.HTMLProps<HTMLButtonElement>) => {
  return (
    <>
      <GradientBox
        theme={theme}
        borderColor={borderColor}
        variant={variant}
        id="gradient"
      >
        <ButtonWrapper
          variant={variant}
          onClick={props.onClick}
          theme={theme}
          borderColor={borderColor}
        >
          {children}
        </ButtonWrapper>
      </GradientBox>
    </>
  );
};

export { Button };
