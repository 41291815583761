import { Outlet,  useLocation, useNavigate } from "react-router-dom";
import { ReactSVG } from "react-svg";
import { motion, AnimatePresence } from "framer-motion";
import {
  HomeContainer,
  HomeFooter,
  HomeFooterImageContainer,
  HomeWrapper,
} from "./home/styles";
import React, { useCallback, useEffect, useState } from "react";
import { safelyParseJSON } from "../utils/parse";
import { getThemeService } from "../services/theme";
import MediaMatch from "../components/mediaMatch";

function Root() {
  const [theme, setTheme] = useState<any>();
  const navigate = useNavigate()
  const location = useLocation();
  const load = useCallback(async function load() {
    const themeResponse = await getThemeService();

    setTheme(safelyParseJSON(themeResponse.data.theme));
  }, []);

  useEffect(() => {
    load();
    if(location.pathname === '/') navigate('home')
  }, [load, location, navigate]);

  return (
    <>
      <AnimatePresence>
        <motion.div
          key={1}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1, x: 0 }}
          transition={{ duration: 1 }}
          exit={{ opacity: 0 }}
        >
          <HomeWrapper style={{ backgroundColor: theme?.main_color }}>
            <MediaMatch lessThan="situacao">
              <svg
                data-src={theme?.background_situation}
                fill="currentColor"
                width="100%"
                height="100%"
                style={{ zIndex: "0", position: "absolute" }}
              />
            </MediaMatch>
            <HomeContainer
              background_c={theme?.background_collaborative}
              background_s={theme?.background_situation}
            >
              <Outlet />
              <HomeFooter>
                <MediaMatch greaterThan="situacao">
                  <HomeFooterImageContainer>
                    <ReactSVG src={theme?.footer_collaborative} />
                  </HomeFooterImageContainer>
                </MediaMatch>
                <MediaMatch lessThan="situacao">
                  <HomeFooterImageContainer>
                    <ReactSVG src={theme?.footer_situation} />
                  </HomeFooterImageContainer>
                </MediaMatch>
              </HomeFooter>
            </HomeContainer>
          </HomeWrapper>
        </motion.div>
      </AnimatePresence>
    </>
  );
}

export default (Root);
