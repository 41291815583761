import { createGlobalStyle } from 'styled-components';

import ObservatorioNacionalSans300 from './assets/fonts/ObservatorioNacionalSans-ExtraLight.otf'
import ObservatorioNacionalSans400 from './assets/fonts/ObservatorioNacionalSans-Light.otf'
import ObservatorioNacionalSans500 from './assets/fonts/ObservatorioNacionalSans-Regular.otf'
import ObservatorioNacionalSans600 from './assets/fonts/ObservatorioNacionalSans-Medium.otf'
import ObservatorioNacionalSans700 from './assets/fonts/ObservatorioNacionalSans-SemiBold.otf'
import ObservatorioNacionalSans800 from './assets/fonts/ObservatorioNacionalSans-Bold.otf'
import ObservatorioNacionalSans900 from './assets/fonts/ObservatorioNacionalSans-ExtraBold.otf'
export default createGlobalStyle`
 @font-face {
        font-family: 'Observatorio Nacional Sans';
        src: local('Observatorio Nacional Sans'), local('FontName'),
        url(${ObservatorioNacionalSans300}) format('truetype');
        font-weight: 300;
        font-style: normal;
    }
    @font-face {
        font-family: 'Observatorio Nacional Sans';
        src: local('Observatorio Nacional Sans'), local('FontName'),
        url(${ObservatorioNacionalSans400}) format('truetype');
        font-weight: 400;
        font-style: normal;
    }
    @font-face {
        font-family: 'Observatorio Nacional Sans';
        src: local('Observatorio Nacional Sans'), local('FontName'),
        url(${ObservatorioNacionalSans500}) format('truetype');
        font-weight: 500;
        font-style: normal;
    }
    @font-face {
        font-family: 'Observatorio Nacional Sans';
        src: local('Observatorio Nacional Sans'), local('FontName'),
        url(${ObservatorioNacionalSans600}) format('truetype');
        font-weight: 600;
        font-style: normal;
    }
    @font-face {
        font-family: 'Observatorio Nacional Sans';
        src: local('Observatorio Nacional Sans'), local('FontName'),
        url(${ObservatorioNacionalSans700}) format('truetype');
        font-weight: 700;
        font-style: normal;
    }
    @font-face {
        font-family: 'Observatorio Nacional Sans';
        src: local('Observatorio Nacional Sans'), local('FontName'),
        url(${ObservatorioNacionalSans800}) format('truetype');
        font-weight: 800;
        font-style: normal;
    }
    @font-face {
        font-family: 'Observatorio Nacional Sans';
        src: local('Observatorio Nacional Sans'), local('FontName'),
        url(${ObservatorioNacionalSans900}) format('truetype');
        font-weight: 800;
        font-style: normal;
    }

    body {
        margin: 0;
        overflow: hidden;
        font-family: Observatorio Nacional Sans, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
            'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
            sans-serif;
        font-weight: 400;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;

        a{
            text-decoration: none;
            color: white;
        }
    }
`;