import { ReactSVG } from "react-svg";
import { motion, AnimatePresence } from "framer-motion";

import {
  Description,
  SubeixoContent,
  SubeixoHeader,
  IconContainer,
  ProductName,
  BrandContainer,
  SvgBrandContainer,
  SubeixoHeaderImageContainer,
  SubeixoContentImageBackground,
  ButtonContainer,
} from "./styles";

import { CarouselComponent } from "../../components/carousel";
import MediaMatch from "../../components/mediaMatch";
import React, { useEffect, useState } from "react";
import { getAxisByIdService } from "../../services/home";
import { safelyParseJSON } from "../../utils/parse";
import { getThemeService } from "../../services/theme";
import "external-svg-loader";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { getProductByIdService } from "../../services/product";
import { Breadcrumb } from "../../components/breadcrumb";
import { buildBreadcrumb, removeFromBreadcrumb } from "../../utils/createBreadcrumb";
import { Button } from "../../components/button";
import { ButtonExpand } from "../../components/button-expand";
import Loader from "../../components/loader";

function Produto() {
  const [theme, setTheme] = useState<any>();
  const [product, setProduct] = useState<any>();
  const [children, setChildren] = useState<any>([]);
  const [parent, setParent] = useState<any>();
  const [breadcrumb, setBreadcrumb] = useState<any[]>([]);
  const [currentGridPage, setCurrentGridPage] = useState<any>();
  const [isLoading, setIsLoading] = useState(false);
  const [referenceColor, setReferenceColor] = useState<string>('#000000');
  const [referenceSubtitle, setReferenceSubtitle] = useState<string>('');
  const params = useParams();
  const navigate = useNavigate();

  async function load() {
    setIsLoading(true);
    const productResponse = await getProductByIdService(params.id);
    const parentResponse = await getAxisByIdService(
      productResponse.data.product.parent
    );
    const themeResponse = await getThemeService();

    setProduct(safelyParseJSON(productResponse.data.product));
    setChildren(safelyParseJSON(productResponse.data.product.children));
    setTheme(safelyParseJSON(themeResponse.data.theme));
    setParent(parentResponse.data.axis);
    setIsLoading(false);
  }

  const location = useLocation();

  useEffect(() => {
    load();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params]);

  useEffect(() => {
    async function loadBreadcrumb() {
      const breadcrumbs = buildBreadcrumb();
      setBreadcrumb(breadcrumbs);
    }
    loadBreadcrumb();
  }, [location, params.id]);

  useEffect(() => {
    const currentPage = Number(localStorage.getItem("currentPageGrid") ?? 0);
    setCurrentGridPage(currentPage);
  }, []);

  useEffect(() => {
    async function loadReferenceColor() {
      const breadcrumbs = buildBreadcrumb(true);
      if (breadcrumbs?.length) {
        const responseAxis = await getAxisByIdService(breadcrumbs[0]?.id);
        setReferenceColor(responseAxis.data.axis.color);
        setReferenceSubtitle(breadcrumbs[0]?.title);
      }
    }

    loadReferenceColor();

  }, [breadcrumb])

  useEffect(() => {
    return () => {}
  }, []);

  const nextGridPage = () => {
    setCurrentGridPage(currentGridPage + 1);
  };

  const prevGridPage = () => {
    setCurrentGridPage(currentGridPage - 1);
  };

  return (
    <>
      <>
        {isLoading && <Loader />}
        <SubeixoHeader>
          <MediaMatch greaterThan="situacao">
            <SubeixoHeaderImageContainer>
              {theme?.header_collaborative && <ReactSVG src={theme?.header_collaborative} />}
            </SubeixoHeaderImageContainer>
          </MediaMatch>
          <MediaMatch lessThan="situacao">
            <SubeixoHeaderImageContainer>
              {theme?.header_situation && <ReactSVG src={theme?.header_situation} />}
            </SubeixoHeaderImageContainer>
          </MediaMatch>

          <AnimatePresence mode="wait">
            <motion.div
              key={1}
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 1, delay: 1 }}
              exit={{ opacity: 0, x: 2000, animationDuration: "1s" }}
            >
              <BrandContainer>
                <MediaMatch greaterThan="situacao">
                  {/.svg$/.exec(product?.icon) ? (
                    <SvgBrandContainer>
                      <ReactSVG src={product?.icon} />
                    </SvgBrandContainer>
                  ) : (
                    <>
                      <IconContainer src={product?.icon} />
                    </>
                  )}
                </MediaMatch>
                <MediaMatch lessThan="situacao">
                  {/.svg$/.exec(product?.icon) ? (
                    <SvgBrandContainer>
                      <ReactSVG src={product?.icon} />
                    </SvgBrandContainer>
                  ) : (
                    <>
                      <IconContainer src={product?.icon} />
                    </>
                  )}
                </MediaMatch>

                <div style={{zIndex: '1'}}>
                  <ProductName highlight>{product?.title}</ProductName>
                  <ProductName>{referenceSubtitle}</ProductName>
                </div>
              </BrandContainer>

              <Description>{product?.description}</Description>
            </motion.div>
          </AnimatePresence>
        </SubeixoHeader>

        <AnimatePresence mode="wait">
          <motion.div
            key={1}
            initial={{ opacity: 0, scale: 0 }}
            animate={{ opacity: 1, x: 0, scale: 1 }}
            transition={{ duration: 1, delay: 0.5 }}
            exit={{ opacity: 0 }}
          >
            <SubeixoContent>
              <Breadcrumb
                isGrid
                path={breadcrumb}
                active={product?.title}
                color={referenceColor}
              />
              <SubeixoContentImageBackground color={referenceColor}>
                <MediaMatch greaterThan="situacao">
                  {theme?.container2_collaborative && <ReactSVG src={theme?.container2_collaborative} />}
                </MediaMatch>
                <MediaMatch lessThan="situacao">
                  {theme?.container2_situation && <ReactSVG src={theme?.container2_situation} />}
                </MediaMatch>
              </SubeixoContentImageBackground>

              <CarouselComponent
                items={children}
                theme={theme}
                color={referenceColor}
                isGrid
                productId={params?.id}
                itemsToShow={4}
                currentGridPage={currentGridPage}
                nextAction={nextGridPage}
                prevAction={prevGridPage}
              ></CarouselComponent>
              {
                <MediaMatch greaterThan="situacao">
                  <ButtonExpand
                    borderColor={"red"}
                    theme={theme}
                    variant="normal"
                    onClick={() =>
                      navigate(
                        `/conteudo/${product?.id}/single-${localStorage.getItem(
                          "currentPage"
                        )}`
                      )
                    }
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="w-6 h-6"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M3.75 3.75v4.5m0-4.5h4.5m-4.5 0L9 9M3.75 20.25v-4.5m0 4.5h4.5m-4.5 0L9 15M20.25 3.75h-4.5m4.5 0v4.5m0-4.5L15 9m5.25 11.25h-4.5m4.5 0v-4.5m0 4.5L15 15"
                      />
                    </svg>
                  </ButtonExpand>
                </MediaMatch>
              }
            </SubeixoContent>
          </motion.div>
        </AnimatePresence>

        <ButtonContainer>
          <Button
            variant="normal"
            onClick={() => {
              removeFromBreadcrumb(1);
              navigate(`/subeixo/${parent.id}`);
            }}
            theme={theme}
            borderColor={referenceColor}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="w-6 h-6"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M15.75 19.5L8.25 12l7.5-7.5"
              />
            </svg>
            Voltar
          </Button>

          {
            <Button
              variant="large"
              onClick={() => navigate("/home")}
              theme={theme}
              borderColor={theme?.main_color}
            >
              {theme?.icon_situation && <ReactSVG id="home-button" src={theme?.icon_situation} />}
              Home
            </Button>
          }
        </ButtonContainer>
      </>
    </>
  );
}

export default (Produto)