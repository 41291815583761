import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import Root from "./routes/root";
import ErrorPage from "./error-page/error-page";
import Home from "./routes/home/home";
import "bootstrap/dist/css/bootstrap.min.css";
import GlobalStyle from "./globalStyle";
import Subeixo from "./routes/subeixos/subeixos";
import Produto from "./routes/produtos/produtos";
import Conteudo from "./routes/conteudos/conteudo";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Root />,
    errorElement: <ErrorPage />,
    children: [
      {
        path: "subeixo/:id",
        element: <Subeixo />,
      },
      {
        path: "home",
        element: <Home />,
      },
      {
        path: "conteudo/:idProduto/:idConteudo",
        element: <Conteudo />,
      },
      {
        path: "produto/:id",
        element: <Produto />,
      },
    ],
  },
]);

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <GlobalStyle />
    <RouterProvider router={router} />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
