import { AxisContainer, AxisIcon, AxisLabel, SvgContainer } from "./styles";
import { ReactSVG } from "react-svg";

const Icon = ({ axis }: { axis: any }) => {
  return (
    <>
      {/.svg$/.exec(axis.icon) ? (
        <SvgContainer>
          <ReactSVG src={axis.icon} />
          <AxisLabel>{axis.title}</AxisLabel>
        </SvgContainer>
      ) : (
        <AxisContainer style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
          <AxisIcon src={axis.icon} />
          <AxisLabel>{axis.title}</AxisLabel>
        </AxisContainer>
      )}
    </>
  );
};

export { Icon };
