import { styled } from "styled-components";
import { customMedia } from "../mediaMatch";
import { adjustHue, borderColor, lighten } from "polished";

interface PropsGradient {
    theme: any;
    borderColor: any;
    variant: 'normal'|'large'
}
export const GradientBox = styled.div<PropsGradient>`
  
  display: flex;
  align-items: center;

  /* ${customMedia.greaterThan('situacao')`
    margin: auto;
`} */

  position: relative;
  box-sizing: border-box;

  border: 5px;
  color: #FFF;
  background: #000;
  background-clip: padding-box; /* !importanté */
  border: solid 5px transparent; /* !importanté */
  border-radius: 200px;

  &:before {
    content: '';
    position: absolute;
    top: 0; right: 0; bottom: 0; left: 0;
    z-index: -1;
    margin: -10px; /* !importanté */
    border-radius: inherit; /* !importanté */
    background: ${({variant, theme, borderColor}) => {
        return (variant === 'large' ?  `linear-gradient(to bottom right, ${lighten('0.2', borderColor ?? theme?.main_color ?? '#000')}, ${lighten('0.463',adjustHue('280',   borderColor ?? theme?.main_color ?? '#000'))})` : borderColor ?? theme?.main_color ?? '#000')
    }};

${customMedia.greaterThan('situacao')`
     margin: -5px;
`}
  }`

interface PropsButtonWrapper {
    theme: any;
    borderColor: any;
    variant: 'large'| 'normal'
}
export const ButtonWrapper = styled.button<PropsButtonWrapper>`
    z-index: 2;
    border-radius: 25%;
    border-color: transparent;
    display: inline-flex;
    padding: ${({variant}) => {
            const padding = variant === 'normal'? '54px 120px 54px 80px;' : '64px 120px;'
            return padding;
        }
    };
    justify-content: center;
    align-items: center;
    gap: 20px;


    border-radius: 200px;
    /* border: ${({variant, borderColor, theme}) => {
        return variant === 'normal' ? `8px solid ${ borderColor }` : `8px solid #2C86C6`;
    }} ; */
    background: ${({theme}) => theme.main_color ?? 'transparent' };

    font-size: 80px;
    font-weight: 500;
    color: white;
    svg {
        width: 70px;
    }

    ${customMedia.greaterThan('situacao')`
        display: inline-flex;
        padding: ${({variant}: {variant: any}) => {
            const padding = variant === 'normal'? '24px 38px 24px 24px;' : '16px 20px;'
            return padding;
        }
    };
        justify-content: center;
        align-items: center;
        gap: 12px;
        font-size: 24px;
        svg {
            width: ${({variant}: {variant: any}) => {
                const padding = variant === 'normal'? '24px' : '36px;'
                return padding;
            }
        }
    `}
`