import api from "./api";


export function homeListService() {
    return api.get('/axis')
}

export function homeListSortedService() {
    return api.get('/home-axis-order')
}

export function getAxisByIdService(id: any) {
    return api.get('/axis/'+id)
}
