import { Carousel } from "react-bootstrap";
import { motion, AnimatePresence } from "framer-motion";
import {
  CarouselGridWrapper,
  CarouselPageWrapper,
  CarouselWrapper,
  ContentWrapper,
  NextArrowIcon,
  PrevArrowIcon,
} from "./styles";
import { Icon } from "../icon";
import { useEffect, useState } from "react";
import { ReactSVG } from "react-svg";
import MediaMatch from "../mediaMatch";
import { Link } from "react-router-dom";
import { ButtonExpand } from "../button-expand";
import { addToBreadcrumb } from "../../utils/createBreadcrumb";

const CarouselIconPage = ({ items }: { items: any[] }) => {
  return (
    <CarouselPageWrapper>
      {items.map((a, index) => {
        const route = a.type === "product" ? "produto" : "subeixo";
        return (
          <AnimatePresence key={`${Date.now().toString()}${index}`} mode="wait">
            <motion.div
              key={1}
              initial={{ opacity: 0, scale: 0.5 }}
              animate={{ opacity: 1, x: 0, scale: 1, zIndex: 3 }}
              transition={{ duration: 0.5, delay: 1 }}
              exit={{ opacity: 0, x: 2000, animationDuration: "1s" }}
            >
              <Link key={index} to={`/${route}/${a.id}`} onClick={() => addToBreadcrumb({id: a.id, type: a.type, title: a.title})}>
                <Icon axis={a} />
              </Link>
            </motion.div>
          </AnimatePresence>
        );
      })}
    </CarouselPageWrapper>
  );
};

const CarouselContentPage = ({
  items,
  theme,
  isSingle,
  productId = null,
}: {
  items: any[];
  theme?: any;
  isSingle?: boolean;
  productId?: any;
}) => {
  return (
    <CarouselGridWrapper isSingle={isSingle} style={{justifyContent: `${items.length < 2 ? 'center' : 'unset'}`}}>
      {items.map((a, index) => {
        const route = "conteudo";
        return (
          <ContentWrapper isSingle={isSingle} key={`${index+productId}`}>
            <AnimatePresence mode="wait">
              <motion.div
                key={1}
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 1, delay: 1.5 }}
                exit={{ opacity: 0, x: 2000, animationDuration: "1s" }}
              >
                <iframe
                  id="iframe"
                  width="1540"
                  height="865"
                  src={a.content}
                ></iframe>
                {!isSingle && (
                  <MediaMatch lessThan="situacao">
                    <Link key={index} to={`/${route}/${productId}/${a.id}`}>
                      <ButtonExpand
                        borderColor={"red"}
                        theme={theme}
                        variant="normal"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth={1.5}
                          stroke="currentColor"
                          className="w-6 h-6"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M3.75 3.75v4.5m0-4.5h4.5m-4.5 0L9 9M3.75 20.25v-4.5m0 4.5h4.5m-4.5 0L9 15M20.25 3.75h-4.5m4.5 0v4.5m0-4.5L15 9m5.25 11.25h-4.5m4.5 0v-4.5m0 4.5L15 15"
                          />
                        </svg>
                      </ButtonExpand>
                    </Link>
                  </MediaMatch>
                )}
              </motion.div>
            </AnimatePresence>
          </ContentWrapper>
        );
      })}
    </CarouselGridWrapper>
  );
};

const CarouselComponent = ({
  items,
  theme,
  color,
  prevAction,
  nextAction,
  isGrid = false,
  itemsToShow = 8,
  isSingle = false,
  productId = null,
  currentIndexIframe = 0,
  currentGridPage = 0,
}: {
  items: any[];
  theme: any;
  color?: any;
  isGrid?: boolean;
  itemsToShow?: any;
  isSingle?: boolean;
  productId?: any;
  currentIndexIframe?: any;
  currentGridPage?: any;
  prevAction?: any;
  nextAction?: any;
}) => {
  const intervalMileSeconds = 7 * 24 * 60 * 60 * 1000;
  const [pagesArray, setPagesArray] = useState<any[]>([]);
  const [totalPages, setTotalPages] = useState<number>(0);
  const [currentPage, setCurrentPage] = useState<number>(
    currentIndexIframe ?? 0
  );
  const isProductPage = (window.location.href.includes('produto'))
  useEffect(() => {
    
    const itemsPerPage = itemsToShow;
    if (items) {
      setTotalPages(Math.ceil(items.length / itemsPerPage));
    }

    const pagesArrayAux = [];

    for (let page = 0; page < Math.ceil(items.length / itemsPerPage); page++) {
      pagesArrayAux.push(
        items.slice(page * itemsPerPage, (page + 1) * itemsPerPage)
      );
    }

    setPagesArray(pagesArrayAux);
  }, [items]);

  useEffect(() => {
    localStorage.setItem("currentPage", JSON.stringify(currentPage));
  }, [currentPage]);

  useEffect(() => {
    setCurrentPage(currentIndexIframe as number);
  }, [currentIndexIframe]);

  useEffect(() => {
  }, [pagesArray]);

  return (
    <CarouselWrapper
      isSingle={isSingle}
      isGrid={isGrid}
      color={color ?? theme?.main_color}
      isProductPage={isProductPage}
    >
      <Carousel
        indicators={totalPages > 1}
        activeIndex={isSingle ? currentPage : undefined}
        onSelect={(e) => {
          if (!isSingle && isGrid) {
            localStorage.setItem("currentPageGrid", JSON.stringify(e));
          }
          setCurrentPage(e);
        }}
        wrap={false}
        touch={true}
        slide={true}
        interval={intervalMileSeconds}
        nextIcon={
          items?.length > 0 && 
          <NextArrowIcon
            onClick={() => {
              if (isSingle || isGrid) {
                nextAction(items[currentPage+1]);
                setCurrentPage(currentPage+1)
              }
              
            }}
            color={color ?? theme?.main_color}
          >
            <MediaMatch greaterThan="situacao">
              <ReactSVG src={theme?.arrows_collaborative} />
            </MediaMatch>
            <MediaMatch lessThan="situacao">
              <ReactSVG src={theme?.arrows_situation} />
            </MediaMatch>
          </NextArrowIcon>
        }
        prevIcon={
          items?.length > 0 &&
          <PrevArrowIcon
            onClick={() => {
              if (isSingle || isGrid) {
                prevAction(items[currentPage-1]);
                setCurrentPage(currentPage-1)
              }
            }}
            color={color ?? theme?.main_color}
          >
            <MediaMatch greaterThan="situacao">
              <ReactSVG src={theme?.arrows_collaborative} />
            </MediaMatch>
            <MediaMatch lessThan="situacao">
              <ReactSVG src={theme?.arrows_situation} />
            </MediaMatch>
          </PrevArrowIcon>
        }
      >
        {pagesArray.map((a, index) => {
          return (
            <Carousel.Item key={`${index+productId}`}>
              {!isGrid ? (
                <CarouselIconPage items={a} />
              ) : (
                <CarouselContentPage
                  isSingle={isSingle}
                  items={a}
                  theme={theme}
                  productId={productId}
                />
              )}
            </Carousel.Item>
          );
        })}
      </Carousel>
    </CarouselWrapper>
  );
};

export { CarouselComponent };
