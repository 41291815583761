import axios from 'axios'

const BASE_URL = process.env.REACT_APP_API_URL;
// const BASE_URL = 'http://localhost:3333';

const api = axios.create({
    baseURL: BASE_URL,
    headers: {
        Authorization: `bearer Mw.V-NHeLMtr-wEdLS7JynzMnDujTgOoIgIwEZowulcvGgyAzfZHBZNg2Xd1yHw`
    },
    transitional: {
        forcedJSONParsing: true
    }
});

export default api
