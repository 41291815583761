import { ActiveItem, BreadcrumbPath, BreadcrumbWrapper } from "./styles";

const Breadcrumb = ({
  color,
  active,
  path,
  contentParent,
  isGrid = false,
  isSingle = false,
}: {
  color?: string;
  active: string;
  path: any;
  isGrid?: boolean;
  contentParent?: any;
  isSingle?: boolean;
}) => {

  return (
    <BreadcrumbWrapper isGrid={isGrid} isSingle={isSingle}>
      <BreadcrumbPath>{"Prospectiva e Inteligência > "}</BreadcrumbPath>
      {path?.map((item: any) => {
        return <BreadcrumbPath>{item + " > "}</BreadcrumbPath>;
      })}
      <ActiveItem color={color}>{active}</ActiveItem>
    </BreadcrumbWrapper>
  );
};

export { Breadcrumb };
