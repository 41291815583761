import styled from "styled-components"

import  { customMedia } from "../../components/mediaMatch";


export const SubeixoWrapper = styled.div`

`

interface SubeixoProps {
    background_s?: string
    background_c?: string
}
export const SubeixoContainer = styled.div<SubeixoProps>`
    position: relative;
    height: 100%;
    justify-content:space-evenly;
    color: white;
    background-image: url(${(props) => props.background_s});
    background-repeat: no-repeat;
    ${customMedia.greaterThan('situacao')`
        background-image: url(${(props: any) => `${props.background_c}?external`});
        display: flex;
        height: 684px
    `}

`

export const SubeixoHeader = styled.div`
    /* height: 620px; */
    width: 100%;
    padding-left: 240px;
    padding-right: 240px;
    margin-bottom: 330px;
    justify-content:space-evenly;
    align-items: center;
    color: white;
    background-repeat: no-repeat;
    ${customMedia.greaterThan('situacao')`
        width: 705px;
        margin-left: 80px;
        margin-right: 120px;
        padding: 0;
        margin-bottom: 0;
    `}
`
export const SubeixoHeaderImageContainer = styled.div`
    width: 100%;
    height: 260px;
    background-repeat: no-repeat;
    display: block;
    position: absolute;
    width: 100%;
    margin: 0;
    left: 0;
    ${customMedia.greaterThan('situacao')`
        background-image: url();
        width: 705px;
        padding: 0;
        margin-bottom: 0;
    `}

`


export const SubeixoContent = styled.div`
    position: relative;
    width: 3840px;
    height: 2258px;
    display: flex;
    margin-left: 240px;
    margin-right: 240px;
    justify-content:space-evenly;
    align-items: center;
    color: white;
    background-repeat: no-repeat;
    ${customMedia.greaterThan('situacao')`
        height: 460px;
        margin: 100px 80px 125px 0;
        width: 4007px;
    `}
`

interface PropsSubeixoContentImageBackground {
    color: string;
}

export const SubeixoContentImageBackground = styled.div<PropsSubeixoContentImageBackground>`
    position: absolute;
    width: 3840px;
    height: 2258px;
    display: flex;
    margin-left: 240px;
    margin-right: 240px;
    justify-content:space-evenly;
    align-items: center;
    color: white;
    background-repeat: no-repeat;
    ${customMedia.greaterThan('situacao')`
        height: 460px;
        width: 4007px;
    `}
    svg {
        defs {
            linearGradient {
                stop{
                    stop-color: ${(props) => props.color};
                }

                stop[offset='1']{
                    stop-color: ${(props) => props.color}bb;
                }
            }
        }
    }
`

export const BrandContainer = styled.div`
    display: flex;
    align-items: center;
`


export const ProductName = styled.p<{highlight?: boolean}>`
    color: #FFF;
    font-family: 'Observatorio Nacional Sans';
    font-size:  ${(props) => props.highlight ? '224px' : '220px'};
    font-style: normal;
    /* font-weight: 900; */
    line-height: normal;
    font-weight: ${(props) => props.highlight ? '900' : '400'};
    margin: 0;
    margin-top: ${(props) => props.highlight ? '240px' : '16px'};
    margin-left: 80px;
    ${customMedia.greaterThan('situacao')`
        font-size:  ${(props: any) => props.highlight ? '56px' : '48px'};
        margin-top: ${(props: any) => props.highlight ? '80px' : '2PX'};
        margin-left: 24px;
    `}
`

export const IconContainer = styled.img`
    margin-top: 240px;
    width: 573px;
    ${customMedia.greaterThan('situacao')`
        width: 152px;
        margin-top: 80px;
    `}
    
`


export const Description = styled.p`
    margin-top: 24px;
    min-height: 208px;
    max-height: 208px;
    /* margin-left: 240px;
    margin-right: 240px; */
    width: 3840px;
    color: #FFF;
    font-family:  'Observatorio Nacional Sans';
    font-size: 80px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; /* 120px */
    ${customMedia.greaterThan('situacao')`
        font-size: 24px;
        width: 705px;
    `}
`

export const SubeixoFooter = styled.div`
    
    bottom: 0;
    height: 590px;
    width: 100%;
    display: flex;
    justify-content:space-evenly;
    align-items: center;
    color: white;
    ${customMedia.greaterThan('situacao')`
        position: absolute;
        height: 375px;
    `}
`

export const SubeixoFooterImageContainer = styled.div`
    
    bottom: 0;
    height: 590px;
    width: 100%;
    display: flex;
    justify-content:space-evenly;
    align-items: center;
    color: white;
    background-repeat: no-repeat;
    ${customMedia.greaterThan('situacao')`
        height: 170px;
    `}


    svg {
        width: 4320px;
        height: 592px;
        ${customMedia.greaterThan('situacao')`
            width: 4992px;
            height: 375px;
        `}
    }
`

export const DataDrivenIcon = styled.div`
    display: flex;
    position: absolute;
    top: -200px;
    height: 690px;
    ${customMedia.greaterThan('situacao')`
        top: -60px;
        height: 100%;
    `}
`

export const FooterIcon = styled.div`
    display: flex;
    position: absolute;
    bottom: calc(-690px + 200px);
    gap: 680px;
    height: 690px;
    ${customMedia.greaterThan('situacao')`
        bottom: calc(-180px + 70px);
        gap: 290px;
        height: 180px;
    `}
`

export const AxisIconContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`

export const AxisIconLabel = styled.p`
    width: 600px;
    overflow: hidden;
    color: #FFF;
    text-align: center;
    font-family: Observatorio Nacional Sans;
    font-size: 80px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-align: center;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    ${customMedia.greaterThan('situacao')`
        font-size: 24px;
        width: 290px;
        -webkit-line-clamp: 2;
    `}
`

export const SvgContainer = styled.div`
    div {
        display: flex;
        justify-content: center;
    }

    svg {
        width: 400px;
        height: 400px;
        ${customMedia.greaterThan('situacao')`
            width: 120px;
            height: 120px;
        `}
    }
`

export const SvgBrandContainer = styled.div`
    margin-top: 240px;
    ${customMedia.greaterThan('situacao')`
        margin-top: 80px;
    `}
    div {
        display: flex;
        justify-content: center;
    }

    svg {
        width: 600px;
        height: 600px;
        ${customMedia.greaterThan('situacao')`
            width: 186px;
            height: 186px;
        `}
    }
`

export const SubeixoFooterbackground = styled.div``

export const ButtonContainer = styled.div`
    width: 50%;
    display: flex;
    justify-content: space-between;
    /* gap: 320px; */
    position: absolute;
    z-index: 1;
    margin-left: 240px;
    margin-top: 200px;

     #home-button {
        svg {
            width: 120px;
            height: 120px;
            fill: transparent;
        }
        defs {
            linearGradient:last-child {
                stop {
                    stop-color: transparent;
                }
                
            }
        }
        ${customMedia.greaterThan('situacao')`
           svg {
            width: 60px;
            height: 60px;
            fill: transparent;
        }
        `}
        
    }
    ${customMedia.greaterThan('situacao')`
        width: 700px;
        margin-left: 0;
        left: 80px;
        bottom: 85px;
    `}
`