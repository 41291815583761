import { safelyParseJSON } from "./parse"

const buildBreadcrumb = (completeNode?:boolean) => {
    const arrayOfBreads: any[] = safelyParseJSON(localStorage.getItem('breadcrumb'))?.map((item: any) => {
        if (completeNode) return item;
        return item.title;
    } );
    arrayOfBreads?.pop();
    return arrayOfBreads || [];
}

const addToBreadcrumb = ({id, title, type}: {id: any, title: string, type: 'axis'|'product'}) => {
    
    const breadcrumb: any[] = safelyParseJSON(localStorage.getItem('breadcrumb')) || []
    breadcrumb?.push({id, type, title});
    localStorage.setItem('breadcrumb', JSON.stringify(breadcrumb))

}

const removeFromBreadcrumb = (quantityToRemove: number = 1) => {
    const breadcrumb: [] = safelyParseJSON(localStorage.getItem('breadcrumb')) || []
    for(let i = 0; i < quantityToRemove; i++) {
        breadcrumb?.pop()
    }
    localStorage.setItem('breadcrumb', JSON.stringify(breadcrumb))
}


export {buildBreadcrumb, addToBreadcrumb, removeFromBreadcrumb}